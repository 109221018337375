import React from "react";
import { FiCalendar } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import BreadcrumbNav from "./BreadcrumbNav";
import { getnewsbycategoryApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";

function CategoryView() {
    const [Data, setData] = useState([]);
    const query = useQuery();
    const catid = query.get("id");
    const categoryName = query.get("name");;
    const [loading, setLoading] = useState(true);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        getnewsbycategoryApi(
            catid,
            "",
            "0",
            "10",
            (response) => {
                setData(response.data);
                setLoading(false);
            },
            (error) => {
                if (error === "No Data Found") {
                    setData("");
                    setLoading(false);
                }
            }
        );
    }, [catid,currentLanguage]);

    return (
        <>
            <BreadcrumbNav SecondElement={categoryName} ThirdElement="0" />
            <div id="cv-main">
                {/* <p>my corrent location is {loca.pathname}</p>
        {loca.pathname === `/category-view` ?
        <button
        onClick={ () => alert('you are awesome')} >

          click me
        </button>
        : null
        } */}
                {/* <BreadcrumbSection/> */}
                <div id="cv-content" className="my-5 container">
                    {loading ? (
                        <div>
                            <Skeleton height={200} count={3} />
                        </div>
                    ) : (
                        <div className="row">
                            {Data.length > 0 ? (
                                Data.map((element) => (
                                    <div className="col-lg-3 col-md-4 col-12 " key={element.id}>
                                        <Link id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                                            <div id="cv-card" className="card">
                                                <img id="cv-card-image" src={element.image} className="card-img" alt="..." />
                                                <div id="cv-card-body" className="card-body">
                                                    <button id="cv-btnCatagory" className="btn btn-sm" type="button">
                                                        {element.category_name}
                                                    </button>
                                                    <p id="cv-card-title" className="card-title">
                                                        {element.title.slice(0, 150)}...
                                                    </p>
                                                    <p id="cv-card-date">
                                                        <FiCalendar size={18} id="cv-logoCalendar" />
                                                        {element.date.slice(0, 10)}
                                                    </p>
                                                    {/* <Link id='btncvRead' className='btn overlay' type="button" to="/news-view" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center my-5">{translate("nodatafound")}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default CategoryView;
