import React from "react";
import { FiCalendar } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import { getnewsbytagApi } from "../store/actions/campaign";

function TagNewsview() {
    const [Data, setData] = useState([]);
    const query = useQuery();
    const Tid = query.get("Tid");

    useEffect(() => {
        getnewsbytagApi(
            Tid,
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        ); // eslint-disable-next-line
    }, []);

    return (
        <div className="my-3">
            <div className="container">
                <div className="row">
                    {Data &&
                        Data.map((element) => (
                            <div className="col-md-4 col-12" key={element.id}>
                                <Link id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                                    <div id="ts-card" className="card">
                                        <img id="ts-card-image" src={element.image} className="card-img" alt="..." />

                                        <div id="ts-card-body" className="card-body">
                                            <button id="ts-btnCatagory" className="btn btn-sm" type="button">
                                                {element.category_name}
                                            </button>
                                            <h5 id="ts-card-title" className="card-title">
                                                {element.title.slice(0, 150)}...
                                            </h5>
                                            <p id="ts-card-date">
                                                <FiCalendar size={18} id="ts-logoCalendar" />
                                                {element.date.slice(0, 10)}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default TagNewsview;
