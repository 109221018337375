import React from 'react'
import HealthNewsSection from './HealthNewsSection'
import BreakingNewsSection from './BreakingNewsSection'
import FirstSection from './FirstSection'
import RecentNewsSection from './RecentNewsSection'
import VideoPlayerSection from './VideoPlayerSection'

function HomePage() {
    return (
        <>
            {/* first section */}
            <div id='first-section' className="">
                <FirstSection />
            </div>

            {/* Breacking News section */}
            <div id='' className=''>
                <BreakingNewsSection/>
            </div>

            {/*/!* Recent News section *!/*/}
            <div id='' className=''>
                <RecentNewsSection/>
            </div>

            {/* Video player section */}
            <div id=''>
                <VideoPlayerSection/>
            </div>

            {/* health News Section */}
            <div id='' className=''>
                <HealthNewsSection/>
            </div>
        </>
    )
}

export default HomePage
