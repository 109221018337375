import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect } from "react";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { categoriesApi } from "../store/actions/campaign";
import { translate } from "../utils";

function Categories(props) {
    const [show, setShow] = useState(false);
    const [Data, setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        categoriesApi(
            "0",
            "16",
            (response) => {
                const responseData = response.data;
                setData(responseData);
            },
            (error) => {
                // console.log(error);
            }
        );
    }, [currentLanguage]);

    return (
        <>
            <Link variant="primary" className="nav-link active me-4" onClick={handleShow}>
            {translate("categories")}
            </Link>

            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{translate("categories")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {Data &&
                        Data.map((element) => (
                            <div id="cat-section-card" key={element.id} className="card">
                                <img id="cat-section-card-image" src={element.image} className="card-img" alt="..." />
                                <div id="cat-section-card-body" className="card-img-overlay">
                                    <h3 id="cat-card-text" className="card-text">
                                        {element.category_name}
                                    </h3>
                                    <button id="btn-cat-more" className="btn" type="button">
                                        <IoArrowForwardCircleSharp size={40} />
                                    </button>
                                </div>
                            </div>
                        ))}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Categories;
