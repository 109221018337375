import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { getnewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";

function RecentNewsSection() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getnewsApi(
            "0",
            "8",
            "",
            "",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };


    return (
        <>
            <div>
                <div id="rns-main">
                    <div className="container">
                        <div className="row">
                            {!Data || Data.length === 0 ? null : (
                                <div id="rns-cards-main" className="">
                                    <div id="rns-head-main" className="">
                                        <h4 id="rns-main-logo">{translate("recentNewsLbl")}</h4>
                                        <Link href="/" id="rns-Viewmore" to="/recent-news-view" onClick={() => scrollToTop()}>
                                            {translate("viewMore")}
                                        </Link>
                                    </div>

                                    <div className="row">
                                        {Data.map((value, index) => {
                                            return (
                                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                                                    <div id="rns-card" className="card" key={index}>
                                                        <img id="rns-image" src={value.image} className="card-img-top" alt="..." />
                                                        <div id="rns-img-overlay" className=" card-inverse">
                                                            <Link id="btnrnsCatagory" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                                {value.category_name}
                                                            </Link>
                                                        </div>
                                                        <div id="rns-card-body" className="card-block">
                                                            <p className="card-title">{value.title.slice(0, 80)}...</p>
                                                            <Link id="btnrnsRead" className="btn overlay" to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id} type="button">
                                                            {translate("readmore")}
                                                                <HiOutlineArrowLongRight id="rns-arrow" size={20} />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RecentNewsSection;
