import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { categoriesApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";

function CatNav() {
    const [Data, setData] = useState([]);

    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        categoriesApi(
            "0",
            "16",
            (response) => {
                const responseData = response.data;
                setData(responseData);
            },
            (error) => {
                // console.log(error);
            }
        );
    }, [currentLanguage]);

    return (
        <div>
            <Navbar id="cn-main" expand="lg">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {Data &&
                                Data.map((element) => (
                                    <Link id="catNav-links" key={element.id} to={{
                                        pathname: "/category-view",
                                        search: `?id=${element.id}&uid=1&name=${element.category_name}`
                                    }}>
                                        {" "}
                                        <b>{element.category_name}</b>{" "}
                                    </Link>
                                ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default CatNav;
