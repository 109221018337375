import React, { useEffect, useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import VideoPlayerModal from "./VideoPlayerModal";
import { getvideoApi } from "../store/actions/campaign";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { translate } from "../utils"

function VideoPlayerSection() {
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        getvideoApi(
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };


    return !Data || Data.length === 0 ? null : (
        <div id="vps-main">
            <div className="container">
                <div className="row">
                    <div id="vps-head-main" className="">
                        <p id="vps-main-logo">{translate("video-ews-explore")}</p>
                        <Link href="/" id="vps-Viewmore" onClick={() => scrollToTop()} to={"/video-news-view"}>
                        {translate("viewMore")}
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div id="vps-body-left">
                            {Data[0] ? (
                                <Card id="vps-main-card" className="text-black">
                                    <Card.Img id="vps-main-image" src={Data[0].image} alt="Card image" />

                                    <Card.ImgOverlay>
                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[0].content_value)}>
                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                        </Link>
                                        <p id="vps-card-title">
                                            <b>{Data[1].title}</b>
                                        </p>
                                    </Card.ImgOverlay>
                                </Card>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div id="vps-body-right">
                            {Data[1] ? (
                                <Card id="vps-image-cards" className="text-black second_video">
                                    <Card.Img id="vps-secondry-images" src={Data[1].image} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[1].content_value)}>
                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                        </Link>
                                        <p id="vps-card-title">
                                            <b>{Data[1].title}</b>
                                        </p>
                                    </Card.ImgOverlay>
                                </Card>
                            ) : null}

                            {Data[2] ? (
                                <Card id="vps-image-cards" className="text-black third_video">
                                    <Card.Img id="vps-secondry-images" src={Data[2].image} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[2].content_value)}>
                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                        </Link>
                                        <p id="vps-card-title">
                                            <b>{Data[2].title}</b>
                                        </p>
                                    </Card.ImgOverlay>
                                </Card>
                            ) : null}
                        </div>
                    </div>

                    <VideoPlayerModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        // backdrop="static"
                        keyboard={false}
                        url={Video_url}
                        // title={Data[0].title}
                    />
                </div>
            </div>
        </div>
    );
}

export default VideoPlayerSection;
