import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import React from "react";
import { getbreakingNewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";

function RelatedBreakingNews() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
            },
            (error) => {
                if (error === "No Data Found") {
                    setData("");
                }
            }
        );
    }, [currentLanguage]);

    return (
        <div>
            {Data.length === 0 ? (
                "loading"
            ) : (
                <div id="rbn-main">
                    <nav id="rbn-cat-nav" className="navbar">
                        <h4 id="nav-logo">
                            <b>{translate("related-news")}</b>
                        </h4>
                    </nav>
                    {Data &&
                            Data.map((element) => (
                                <div key={element.id}>
                                    <Link id="Link-all" to={"/breaking-news?BNid=" + element.id}>
                                        <div id="rbn-card" className="card">
                                            <img id="rbn-image" src={element.image} className="card-img-top" alt="..." />
                                            <div id="rbn-card-body" className="rbn-card-body">
                                                <Link id="btnrbnCatagory" className="btn btn-sm" type="button" to="/breaking-news">
                                                {translate("breakingnews")}
                                                </Link>
                                                <h6 id="rbn-card-text" className="card-text">
                                                    {element.title.slice(0, 40)}...
                                                </h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                        ))}
                </div>
            )}
        </div>
    );
}

export default RelatedBreakingNews;
