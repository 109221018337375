import { store } from "../store/store";

export const getSiblings = function (elem) {
    let siblings = [];
    let sibling = elem.parentNode.firstChild;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};

export const getClosest = function (elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

export const slideUp = (element, duration = 500) => {
    return new Promise(function (resolve) {
        element.style.height = element.offsetHeight + "px";
        element.style.transitionProperty = `height, margin, padding`;
        element.style.transitionDuration = duration + "ms";
        // element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;
        window.setTimeout(function () {
            element.style.display = "none";
            element.style.removeProperty("height");
            element.style.removeProperty("padding-top");
            element.style.removeProperty("padding-bottom");
            element.style.removeProperty("margin-top");
            element.style.removeProperty("margin-bottom");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition-duration");
            element.style.removeProperty("transition-property");
            resolve(false);
        }, duration);
    });
}

export const slideDown = (element, duration = 500) => {
    return new Promise(function () {
        element.style.removeProperty("display");
        let display = window.getComputedStyle(element).display;

        if (display === "none") display = "block";

        element.style.display = display;
        let height = element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;
        // element.offsetHeight;
        element.style.transitionProperty = `height, margin, padding`;
        element.style.transitionDuration = duration + "ms";
        element.style.height = height + "px";
        element.style.removeProperty("padding-top");
        element.style.removeProperty("padding-bottom");
        element.style.removeProperty("margin-top");
        element.style.removeProperty("margin-bottom");
        window.setTimeout(function () {
            element.style.removeProperty("height");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition-duration");
            element.style.removeProperty("transition-property");
        }, duration);
    });
}

export const slideToggle = (element, duration = 500) => {
    if (window.getComputedStyle(element).display === "none") {
        return slideDown(element, duration);
    } else {
        return slideUp(element, duration);
    }
}

// is login user check
export const isLogin = () => {
    let user = store.getState().user
    if (user) {
        try {
            // user = JSON.parse(user);
            if (user.data.firebase_id) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }
    return false;
}

export const translate = (label, defaultLabel = null) => {
    /*Set default Label only if you want custom label */
    let langLabel = store.getState().languages.currentLanguageLabels.data && store.getState().languages.currentLanguageLabels.data[label]
    // console.log(langLabels);
    if (langLabel) {
        return langLabel
    } else {
        return (!defaultLabel) ? label : defaultLabel;
    }
}


// set active element of navbar
export function setActiveLink() {
const links = document.querySelectorAll('.nav-item a');
    links.forEach(link => {
        if (link.href === window.location.href) {
        link.classList.add('active');
        } else {
        link.classList.remove('active');
        }
    });
}

// Call setActiveLink on page load and after a delay on link click
window.addEventListener('load', setActiveLink);
document.querySelectorAll('.nav-item a').forEach(link => {
    link.addEventListener('click', () => {
        setTimeout(setActiveLink, 100);
    });
});

// if user click on logo then active elem remove from other navigation and active home
export function activateHomeLink() {
    const homeLink = document.querySelector('#nav-links[href="/"]');
    if (homeLink) {
        homeLink.click();
    }
}

 // server image error
 export const imgError = (e) => {
    e.target.src = "/images/no_image.jpeg"
}
