import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { getpagesApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import { Modal } from "antd";
import { translate } from "../utils"

function TermsCondition(props) {
    const [loading, setLoading] = useState(true);
    const [Data, setData] = useState({
        page_content: "",
    });

    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        setLoading(true);
        getpagesApi(
            (response) => {
                let termsandcondition = response.data.filter((data) => data.title === "Terms & Conditions");
                setData(termsandcondition[0]);
                setLoading(false);
            },
            (error) => {
                setData("");
                setLoading(false);
                console.log(error);
            }
        );
    }, [currentLanguage]);
    return (
        <Modal centered className="custom-modal" open={props.modal1Open} maskClosable={false} onOk={() => props.setModal1Open(false)} onCancel={() => props.setModal1Open(false)} footer={false} id="modaltp">
            {loading ? <Skeleton height={400} /> : <div>{Data.page_content ? <p id="pp-modal-body" className="p-3 mb-0" dangerouslySetInnerHTML={{ __html: Data.page_content }}></p> : <p className="noData">{translate("nodatafound")}</p>}</div>}
        </Modal>
    );
}

export default TermsCondition;
