import React, { useState } from "react";
import { useEffect } from "react";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Breathing } from "react-shimmer";
import { getbreakingNewsApi } from "../store/actions/campaign";
import SwiperCore, { Navigation, Pagination,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";

SwiperCore.use([Navigation, Pagination,Autoplay]);

function NewsCardFS() {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData("");
                }
            }
        );
    }, [currentLanguage]);

    const truncate = (input) => (input?.length > 180 ? `${input.substring(0, 180)}...` : input);

    const swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: false,
        autoplay:{
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: { clickable: true },
    };

    return (
        <Swiper {...swiperOption}  className="custom-swiper">
            {isLoading ? (
                // Show skeleton loading when data is being fetched
                <div className="col-12 loading_data">
                    <Skeleton height={20} count={22} />
                </div>
            ) : Data.length > 0 ? (
                Data.slice(0, 3).map((item) => (
                    <SwiperSlide key={item.id}>
                        <div id="fs-main" className="h-100">
                            <div id="body-first-section" className="container">
                                <div className="row">
                                    <div className="col-xl-7 order-1 order-xl-0 col-12 d-flex">
                                        <div id="Left-first-section" className="my-auto">
                                            <Link id="btnCatagory" className="btn" type="button" to={"/breaking-news?BNid=" + item.id}>
                                                {item.content_type}
                                            </Link>
                                            <p id="Top-Title">
                                                <b>{item.title}</b>
                                            </p>
                                            <div id="Top-Description" dangerouslySetInnerHTML={{ __html: truncate(item.description) }}></div>
                                            <div>
                                                <Link id="btnReadMore" className="btn" type="button" to={"/breaking-news?BNid=" + item.id}>
                                                    <b>{translate("readmore")}</b>
                                                </Link>
                                                <a id="btnpaly" href={item.content_value} className="circle" target="_blank" rel="noreferrer">
                                                    <BsPlayCircle id="btnpaly-logo"  size={40} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 order-0 order-xl-1 col-12">
                                        <div id="right-first-section">
                                            <img src={item.image} className="float-end fs-Newscard-image h-auto" id="fs-Newscard-image" fallback={<Breathing width={800} height={600} />} alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))
            ) : (
                // Show "No data found" message if no data is available
                <div className="col-12 no_data mt-5">
                    <p className="text-center">{translate("add-data-from-admin-panel")}</p>
                </div>
            )}
        </Swiper>
    );
}

export default NewsCardFS;
