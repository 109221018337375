import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom';
import BreadcrumbNav from './BreadcrumbNav'
import { getnotificationApi } from '../store/actions/campaign';
import { translate } from '../utils';
import Skeleton from 'react-loading-skeleton';
import { imgError } from "../utils/index"


function NewsNotification() {

    const [Data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const initialData = useRef([]);

    useEffect(() => {
        initialData.current = Data;
      }, [Data]);

    useEffect(() => {
        getnotificationApi("0", "20", (response) => {
            setData(response.data)
            setIsLoading(false);
        }, (error) => {
            setIsLoading(false);
                if (error === "No Data Found") {
                    setData("");
                }
        });
    }, [])

    return (
        <>
            <BreadcrumbNav SecondElement="Notification"/>

            <div  className='my-3 container'>
                <div className="d-flex bd-highlight mb-3">
                    <Link to='/persnol-notification' id='btnNotification11' className="btn mx-1 bd-highlight">{translate("personalLbl")}</Link>
                    <Link to='/news-notification' id='btnNewsnoti1' className="btn mx-1 bd-highlight">{translate("news")}</Link>
                    {/* <button  id='btnNotification1' className="btn  btn mx-1 ms-auto bd-highlight" onClick={handleDeleteAll} > Delete All</button> */}
                </div>
                <div className='my-3'>
                    {isLoading ? (
                        <div className="col-12 loading_data">
                            <Skeleton height={20} count={22} />
                        </div>
                    ) : Data.length > 0 ? (
                        Data.map((element, index) => (
                            <div className="card my-3" key={index}>
                                <div className="card-body bd-highlight" id='card-noti'>
                                    <img id='noti_profile' src={element.image} alt="notification" onError={imgError}/>
                                    {/* <button className="btn bd-highlight" style={{ color: "#EE2934", background: "" }} id='it' onClick={handletoggle}>
                                        {like ? <AiFillLike size={35} /> : <AiOutlineLike size={35} />}
                                    </button> */}
                                    <div className='Noti-text'>
                                        {/* <p className='bd-highlight'>{element.title}</p> */}
                                        <p>{element.title.slice(0, 100)}...</p>
                                        <p className='bd-highlight'> {element.message.slice(0, 100)}...</p>
                                        <p className='bd-highlight'> {element.date_sent}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 no_data mt-5">
                            <p className="text-center">{translate("nodatafound")}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default NewsNotification
