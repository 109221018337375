import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getnewsbycategoryApi } from "../store/actions/campaign";
import { translate } from "../utils";

function HealthNewsSection() {
    const [Data, setData] = useState([]);

    useEffect(() => {
        getnewsbycategoryApi(
            "5",
            "",
            "0",
            "10",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };


    return (
        <>
            {!Data || Data.length === 0 ? null : (
                <div className="container">
                    <div id="hns-head" className="row">
                        <div id="hns-head-main">
                            <p id="hns-main-logo">{translate("healthnews")}</p>
                            <Link href="/" id="hns-Viewmore" to={"/category-view?id=5&uid=1"} onClick={() => scrollToTop()}>
                            {translate("viewMore")}
                            </Link>
                        </div>
                    </div>

                    <div id="hns-main" className="row">
                        <div id="hns-main-card" className="card col-lg-12 col-xl-4 col-12">
                            <Link id="Link-all" to={"/news-view?Nid=" + Data[0].id + "&Cid=" + Data[0].category_id}>
                                <img id="hns-main-image" src={Data[0].image} className="card-img" alt={Data[0].image_data.other_image} />
                                <div id="hns-main-body" className="card-img-overlay">
                                    <Link id="btnhnsCatagory" className="btn" type="button" to={`/category-view?id=${Data[0].category_id}`}>
                                        {Data[0].category_name}
                                    </Link>
                                    <p id="hns-main-text" className="card-text">
                                        {Data[0].title}
                                    </p>
                                </div>
                            </Link>
                        </div>

                        <div id="hns-center-col" className="col-lg-12 col-xl-4 col-12">
                            {Data.slice(0, 4).map((value, index) => {
                                return (
                                    <Link key={index} id="Link-all" to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id} className="mb-3">
                                        <div id="hns-card" className="card">
                                            <img id="hns-card-image" src={value.image} className="card-img-top" alt={value.image_data.other_image} />
                                            <div className="hns-card-body">
                                                <Link id="btnhnsCatagorysub" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                    {value.category_name}
                                                </Link>
                                                <p id="hns-card-text" className="card-text">
                                                    {value.title.slice(0, 60)}...
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                        <div id="hns-right-col" className="col-lg-12 col-xl-4 col-12">
                            {Data.slice(4, 8).map((value, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <Link id="Link-all" to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id} className="mb-3">
                                            <div id="hns-card" className="card">
                                                <img id="hns-card-image" src={value.image} className="card-img-top" alt={value.image_data.other_image} />
                                                <div className="hns-card-body">
                                                    <Link id="btnhnsCatagorysub" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                        {value.category_name}
                                                    </Link>
                                                    <p id="hns-card-text" className="card-text">
                                                        {value.title.slice(0, 60)}...
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                            {/*<Link id='Link-all' to={"/news-view?Nid=" + Data[5].id + "&Cid=" + Data[5].category_id}>*/}

                            {/*    <div id='hns-card' className="card">*/}
                            {/*        <img id='hns-card-image' src={Data[5].image} className="card-img-top" alt={Data[5].image_data.other_image}/>*/}
                            {/*        <div className="hns-card-body">*/}
                            {/*            <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[5].category_id}`}>{Data[5].category_name}</Link>*/}
                            {/*            <p id='hns-card-text' className="card-text">{Data[5].title.slice(0, 60)}...</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                            {/*<Link id='Link-all' to={"/news-view?Nid=" + Data[6].id + "&Cid=" + Data[6].category_id}>*/}

                            {/*    <div id='hns-card' className="card">*/}
                            {/*        <img id='hns-card-image' src={Data[6].image} className="card-img-top" alt={Data[6].image_data.other_image}/>*/}
                            {/*        <div className="hns-card-body">*/}
                            {/*            <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[6].category_id}`}>{Data[6].category_name}</Link>*/}
                            {/*            <p id='hns-card-text' className="card-text">{Data[6].title.slice(0, 60)}...</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                            {/*<Link id='Link-all' to={"/news-view?Nid=" + Data[7].id + "&Cid=" + Data[7].category_id}>*/}

                            {/*    <div id='hns-card' className="card">*/}
                            {/*        <img id='hns-card-image' src={Data[7].image} className="card-img-top" alt={Data[7].image_data.other_image}/>*/}
                            {/*        <div className="hns-card-body">*/}
                            {/*            <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[7].category_id}`}>{Data[7].category_name}</Link>*/}
                            {/*            <p id='hns-card-text' className="card-text">{Data[7].title.slice(0, 60)}...</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                            {/*<Link id='Link-all' to={"/news-view?Nid=" + Data[8].id + "&Cid=" + Data[8].category_id}>*/}
                            {/*    <div id='hns-card' className="card">*/}
                            {/*        <img id='hns-card-image' src={Data[8].image} className="card-img-top" alt={Data[8].image_data.other_image}/>*/}
                            {/*        <div className="hns-card-body">*/}
                            {/*            <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[8].category_id}`}>{Data[8].category_name}</Link>*/}
                            {/*            <p id='hns-card-text' className="card-text">{Data[8].title.slice(0, 60)}...</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                    <div id="hns-main-mobile"></div>
                </div>
            )}
        </>
    );
}

export default HealthNewsSection;
