import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getnewsbycategoryApi } from "../store/actions/campaign";
import { translate } from "../utils";

function RelatedNewsSection(props) {
    const [Data, setData] = useState([]);
    const catid = props.Cid;

    useEffect(() => {
        getnewsbycategoryApi(
            catid,
            "",
            "0",
            "10",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [catid]);

    return (
        <div>
            {Data.length === 0 ? (
                "loading"
            ) : (
                <div id="RNews-main">
                    <nav id="RNews-cat-nav" className="navbar">
                        <h4 id="nav-logo">
                            <b>{translate("related-news")}</b>
                        </h4>
                    </nav>
                    {Data &&
                        Data.map((element) => (
                            <Link id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id} key={element.id}>
                                <div id="RNews-card" className="card">
                                    <img id="RNews-image" src={element.image} className="card-img-top" alt="..." />
                                    <div id="RNews-card-body" className="RNews-card-body">
                                        <button id="btnRNewsCatagory" className="btn btn-sm" type="button">
                                            {element.category_name}
                                        </button>
                                        <h6 id="RNews-card-text" className="card-text">
                                            {element.title.slice(0, 40)}...
                                        </h6>
                                    </div>
                                    {/* <>
                  {index<=3 ?
                  <div id='RNews-card' className="card">
                  <img  id='RNews-image' src={element.image} className="card-img-top" alt="..."/>
                  <div className="RNews-card-body">
                  <button id='btnRNewsCatagory' className='btn btn-sm' type="button" >{element.category_name}</button>
                  <h6 id='RNews-card-text' className="card-text">{element.title.slice(0,40)}...</h6>
                  </div>
              </div>:null}

                  </> */}
                                </div>
                            </Link>
                        ))}
                </div>
            )}
        </div>
    );
}

export default RelatedNewsSection;
