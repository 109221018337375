import React, { useState } from "react";
import breakingNews2_jpg from "../images/earthImage.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getbreakingNewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Skeleton from "react-loading-skeleton";

SwiperCore.use([Navigation, Pagination]);

function BreakingNewsSection() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData("");
                }
            }
        );
    }, [currentLanguage]);

    const showNavigation = Data.length > 1;

    const swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 2,
        navigation: showNavigation,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },

            768: {
                slidesPerView: 2,
            },

            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
        autoplay: true,
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };


    return (
        <>
            <div id="bns-main">
                <div className="container custom-card">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div id="bns-main-card" className="card">
                                <img id="bns-main-image" src={breakingNews2_jpg} className="card-img" alt="..." />
                                <div id="bns-main-text" className="card-img-overlay">
                                    <p id="bns-logo-col" className="card-text">
                                        <b>
                                            {translate("breaking")} <br /> {translate("news")}
                                        </b>
                                    </p>
                                    <p id="bns-logo-row" className="card-text">
                                        <b>{translate("breakingnews")}</b>
                                    </p>
                                    <Link id="btnbnsViewAll" className="btn" type="button" to="/breaking-news-view" onClick={() => scrollToTop()}>
                                    {translate("viewall")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-8 col-12">
                                <div id="bns-rest-cards">
                                    <Swiper {...swiperOption} >
                                        {isLoading ? (
                                            // Show skeleton loading when data is being fetched
                                            <div className="col-12 loading_data">
                                                <Skeleton height={20} count={22} />
                                            </div>
                                        ) : Data.length > 0 ? (
                                            Data.slice(0, 3).map((element) => (
                                                <SwiperSlide key={element.id}>
                                                    <div id="bns-card" className="card" key={element.id}>
                                                        <Link id="Link-all" to={"/breaking-news?BNid=" + element.id}>
                                                            <img id="bns-image" src={element.image} className="card-img-top" alt="..." />
                                                            <div id="bns-card-body" className="card-body ps-0">
                                                                <Link id="btnbnsCatagory" className="btn btn-sm" type="button" to="/breaking-news-view">
                                                                {translate("breakingnews")}
                                                                </Link>
                                                                <h5 id="bns-card-text" className="">
                                                                    {element.title.slice(0, 50)}...
                                                                </h5>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        ) : (
                                            // Show "No data found" message if no data is available
                                            <div className="col-12 no_data mt-5">
                                                <p className="text-center">{translate("add-data-from-admin-panel")}</p>
                                            </div>
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BreakingNewsSection;
