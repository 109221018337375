import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useQuery } from "../Hooks";
import { Link } from "react-router-dom";
import TagsSection from "./TagsSection";
import { FacebookIcon, WhatsappIcon, TwitterIcon, TwitterShareButton, WhatsappShareButton, FacebookShareButton } from "react-share";
import RelatedBreakingNews from "./RelatedBreakingNews";
import BreadcrumbNav from "./BreadcrumbNav";
import { getbreakingnewsidApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import { Card } from "react-bootstrap";
import { BsFillPlayFill } from "react-icons/bs";
import VideoPlayerModal from "./VideoPlayerModal";

function BreakingNews() {
    const [Data, setData] = useState([]);
    const [FontSize, setFontSize] = useState(14);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = useState(false);
    const query = useQuery();
    const BNid = query.get("BNid");
    const shareUrl = window.location.href;
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        getbreakingnewsidApi(
            BNid,
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [BNid, currentLanguage]);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    return (
        <>
            {Data.length === 0 ? (
                "loading"
            ) : (
                <>
                    <BreadcrumbNav SecondElement="News Details" ThirdElement={Data[0].title} />
                    <div id="B_NV-main">
                        <div id="B_NV-page" className="container">
                            <div className="row">
                                <div className="col-md-7 col-12">
                                    <div id="B_NV-body">
                                        <Link id="btnB_NVCatagory" className="btn btn-sm" type="button" to="/breaking-news-view">
                                            {translate("breakingnews")}
                                        </Link>
                                        <h1 id="B_NV-title">{Data[0].title}</h1>

                                        <div id="B_NV-Header" className="">
                                            <div id="B_NV-left-head"></div>

                                            <div id="B_NV-right-head">
                                                <h6 id="B_NV-Share-Label">{translate("shareLbl")}:</h6>
                                                <FacebookShareButton url={shareUrl} title={Data[0].title + " - Enews"} hashtag={"Enews"}>
                                                    <FacebookIcon size={30} round />
                                                </FacebookShareButton>
                                                <WhatsappShareButton url={shareUrl} title={Data[0].title + " - Enews"} hashtag={"Enews"}>
                                                    <WhatsappIcon size={30} round />
                                                </WhatsappShareButton>
                                                <TwitterShareButton url={shareUrl} title={Data[0].title + " - Enews"} hashtag={"Enews"}>
                                                    <TwitterIcon size={30} round />
                                                </TwitterShareButton>
                                                <Link></Link>
                                            </div>
                                            </div>
                                            <div id="vps-body-left">
                                                {Data[0] ? (
                                                    <Card id="vps-main-card" className="text-black">
                                                        <Card.ImgOverlay>
                                                            <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[0].content_value)}>
                                                                <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                                            </Link>

                                                        </Card.ImgOverlay>
                                                    </Card>
                                                ) : null}
                                            </div>

                                        <img id="B_NV-image" src={Data[0].image} alt="..." />
                                        <nav id="B_NV-functions" className="">
                                            <div id="B_NV-functions-left">
                                                <Form.Label id="B_NV-font-lable">{translate("fontsize")}</Form.Label>
                                                <Form.Range id="B_NV-FontRange" min={14} max={24} step={2} value={FontSize} onChange={(e) => setFontSize(e.target.value)} />
                                                <div className="d-flex justify-content-between">
                                                    <Form.Label id="B_NV-FontRange-labels">14px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">16px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">18px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">20px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">22px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">24px</Form.Label>
                                                </div>
                                            </div>
                                            <div id="B_NV-functions-right"></div>
                                        </nav>
                                        <p id="B_NV-description" style={{ fontSize: `${FontSize}px` }} dangerouslySetInnerHTML={{ __html: Data[0].description }}></p>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <div id="B_NV-right-section">
                                        <RelatedBreakingNews />
                                        <TagsSection />
                                    </div>
                                </div>
                                </div>
                                <VideoPlayerModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    // backdrop="static"
                                    keyboard={false}
                                    url={Video_url}
                                    // title={Data[0].title}
                                />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default BreakingNews;
