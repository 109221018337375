import { GiHamburgerMenu } from "react-icons/gi";
import React, { useEffect, useState, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiBell, BiUserCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getAuth, signOut } from "firebase/auth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dropdown from "react-bootstrap/Dropdown";
import { loadLanguageLabels, loadLanguages, selectCurrentLanguage, selectLanguages, setCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { categoriesApi, getnotificationApi, getusernotificationApi } from "../store/actions/campaign";
import { FaAngleDown } from "react-icons/fa";
import { getSiblings, slideToggle, slideUp, getClosest, isLogin, translate } from "../utils/index";
import { logoutUser, selectUser } from "../store/reducers/userReducer";
import SignInModal from "./SignInModal";
import { toast } from "react-toastify";
import {  webSettingsData } from "../store/reducers/websettingsReducer";

const Newsbar = (props) => {
    const userData = useSelector(selectUser);

    const auth = getAuth();
    const [Data, setData] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [islogout, setIsLogout] = useState(false); // eslint-disable-next-line
    const [isloginloading, setisloginloading] = useState(true); // eslint-disable-next-line
    const [ShowManu, setShowManu] = useState();
    const [notificationbadge, setNotificationBadge] = useState(0);

    const navigate = useNavigate();
    const closeRef = useRef();

    const languagesData = useSelector(selectLanguages);

    const currentLanguage = useSelector(selectCurrentLanguage);

    const websettings = useSelector(webSettingsData);

    // set active element of navbar
    function setActiveLink() {
        const links = document.querySelectorAll('.nav-item a');
        links.forEach(link => {
          if (link.href === window.location.href) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        });
      }

      // Call setActiveLink on page load and after a delay on link click
      window.addEventListener('load', setActiveLink);
      document.querySelectorAll('.nav-item a').forEach(link => {
        link.addEventListener('click', () => {
          setTimeout(setActiveLink, 100);
        });
      });

    // if user click on logo then active elem remove from other navigation and active home
    function activateHomeLink() {

        const homeLink = document.querySelector('#nav-links[href="/"]');
        if (homeLink) {
          homeLink.click();
        }
      }


    useEffect(() => {
        // get categories
        categoriesApi(
            "0",
            "16",
            (response) => {
                setData(response.data);
            },
            (error) => {
                // console.log(error);
            }
        );
        // language laod
        loadLanguages(
            (response) => {
                if (currentLanguage.code == null) {
                    let index = response.data.filter((data) => {
                        if (data.code === "en") {
                            return { code: data.code, name: data.language, id: data.id };
                        } else {
                            return undefined;
                        }
                    });
                    setCurrentLanguage(index[0].language, index[0].code, index[0].id);
                }
            },
            (error) => {
                console.log(error);
            }
        ); // eslint-disable-next-line
    }, []);

    // language change
    const languageChange = (name, code, id) => {
        loadLanguageLabels(code);
        setCurrentLanguage(name, code, id);
    };

    useEffect(() => {
         setActiveLink();
        if (userData.data !== null) {
            setIsLogout(true);
            setisloginloading(false);
        } else {
            setIsLogout(false);
            setisloginloading(true);
        } // eslint-disable-next-line
    }, []);

    // user notification
    const getusernotification = () => {
        getusernotificationApi(
          "0",
          "10",
          (response) => {
            setNotificationBadge(response.data.length)
          },
          (error) => {
            if (error === "No Data Found") {
              setNotificationBadge(0);
            }
          }
        );
      }

      const getnotification = () => {
        getnotificationApi(
          "0",
          "20",
            (response) => {
            setNotificationBadge(response.data.length)
          },
          (error) => {
            if (error === "No Data Found") {
              setNotificationBadge(0)
            }
          }
        );
      }


      useEffect(() => {
        // Make API calls here based on route change
        if (window.location.pathname === '/') {
            getusernotification();
        }else if(window.location.pathname === '/notification' || window.location.pathname === '/persnol-notification') {
            getusernotification();
        }else if (window.location.pathname === '/news-notification') {
            getnotification();
        }// eslint-disable-next-line
      }, [window.location.pathname,isLogin()]);

    const logout = () => {
        handleClose();

        confirmAlert({
            title: "Logout",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        signOut(auth)
                            .then(() => {
                                // // Sign-out successful.
                                // // alert("Sign-out successful.")
                                // localStorage.removeItem("token");
                                // localStorage.removeItem("user");
                                logoutUser();
                                setIsLogout(false);
                                navigate("/");
                            })
                            .catch((error) => {
                                toast.error(error);
                                // An error happened.
                            });
                    },
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (parentEl?.classList.contains("menu-toggle") || target.classList.contains("menu-toggle")) {
            const element = target.classList.contains("icon") ? parentEl : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };

    let userName = "";

    const checkUserData = (userData) => {
        if (userData.data && userData.data.name !== "") {
            return (userName = userData.data.name);
        } else if (userData.data && userData.data.email !== "") {
            return (userName = userData.data.email);
        } else if (userData.data && (userData.data.mobile !== null || userData.data.mobile !== "")) {
            return (userName = userData.data.mobile);
        }
    };


    // set rtl
    const selectedLang = languagesData.find(lang => lang.code === currentLanguage.code);
    useEffect(() => {
        if (selectedLang && selectedLang.isRTL === "1") {
            document.documentElement.dir = "rtl";
            document.documentElement.lang = `${selectedLang && selectedLang.code}`;
        } else {
            document.documentElement.dir = "ltr";
            document.documentElement.lang = `${selectedLang && selectedLang.code}`;
        }
    }, [selectedLang]);







    // this is for mobile devices navbar with humburger menu
    function OffCanvasExample({ name, ...props }) {
        return (
            <>
                <button className="btn" onClick={handleShow}>
                    <GiHamburgerMenu />
                </button>

                <Offcanvas id="Nav-Offcanvas" className="headermodal" show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header closeButton ref={closeRef}>
                        <Offcanvas.Title>
                            <li id="Nav-btns">
                                {islogout && checkUserData(userData) ? (
                                    <Dropdown>
                                        <Dropdown.Toggle id="btnSignIn" className="">
                                            <BiUserCircle size={23} id="btnLogo" />
                                            {userName}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                            <Dropdown.Item id="btnLogout">
                                                <Link id="btnBookmark" to="/bookmark">
                                                {translate("bookmark")}
                                                </Link>
                                            </Dropdown.Item>
                                            {/*<Dropdown.Item id='btnLogout' onClick={changePassword}>*/}
                                            {/*    Change Password*/}
                                            {/*</Dropdown.Item>*/}
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={logout} id="btnLogout" className="">
                                            {translate("logout")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    <Button variant="danger" onClick={() => setModalShow(true)} id="btnSignIn" className="" type="button">
                                        <BiUserCircle size={23} id="btnLogo" />
                                        {translate("login")}
                                    </Button>
                                )}

                                {/* <button id='btnSerch' type="button" onClick={handleSearchModel} className="btn"><BiSearch size={23} /></button> */}
                                {/* {['bottom-end'].map((placement) => (
                                <OverlayTrigger
                                trigger="click"
                                key={placement}
                                placement={placement}
                                overlay={
                                    <Popover id={`popover-positioned-${placement}`}>
                                    <Popover.Body id='nb-popover-body' >
                                    <form id='serchModal' className="d-flex" role="search">
                                                        <input id='inputSerchForm' className="form-control me-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>{setSearch(e.target.value)}}/>
                                                        <button id='btnSerchForm' className="btn" type="submit" onClick={(e)=>{
                                                            e.preventDefault();

                                                        }}>Search</button>
                                                    </form>
                                    </Popover.Body>
                                    </Popover>
                                }
                                >
                                <Button id='btnSerch' className="btn" variant="secondary"><BiSearch size={23} /></Button>
                                </OverlayTrigger>
                            ))} */}
                            </li>

                            <li id="Nav-btns">
                                <Dropdown>
                                    <Dropdown.Toggle id="btnSignIn" className="">
                                        {currentLanguage.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                        {languagesData &&
                                            languagesData.map((data, index) => {
                                                return (
                                                    <Dropdown.Item key={index} id="btnLogout" onClick={() => languageChange(data.language, data.code, data.id)}>
                                                        {data.language}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li id="Nav-btns">{islogout && checkUserData(userData) ? (
                                    <Link to="/notification" id="btnNotification" type="button" className="btn">
                                        <BiBell size={23} /><span className="noti_badge_data">{notificationbadge}</span>
                                    </Link>
                                ) : null}</li>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul className="">
                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/" onClick={handleClose} >
                                        {translate("home")}
                                    </Link>
                                </b>
                            </li>
                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/about-us" onClick={handleClose}>
                                        {translate("aboutus")}
                                    </Link>
                                </b>
                            </li>
                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/live-news" onClick={handleClose}>
                                        {translate("livenews")}
                                    </Link>
                                </b>
                            </li>

                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/breaking-news-view  " onClick={handleClose}>
                                        {translate("breakingnews")}
                                    </Link>
                                </b>
                            </li>
                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/contact-us" onClick={handleClose}>
                                        {translate("contactus")}
                                    </Link>
                                </b>
                            </li>
                            <li className="nav-item has-children">
                                <span className="menu-toggle" onClick={onClickHandler}>
                                    <b>
                                        <p id="nav-links" className="">
                                            {translate("categories")}
                                        </p>
                                    </b>
                                    <i className="">
                                        <FaAngleDown />
                                    </i>
                                </span>
                                <ul className="sub-menu">
                                    {!Data
                                        ? "Loading..."
                                        : Data.map((element, index) => (
                                              <li className="nav-item" key={index}>
                                                  <Link id="catNav-links" key={index} to={"/category-view?id=" + element.id + "&uid=1"} onClick={handleClose}>
                                                      {" "}
                                                      <b>{element.category_name}</b>{" "}
                                                  </Link>
                                              </li>
                                          ))}
                                </ul>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }


    return (
        <>
            <nav className="Newsbar">
                <div className="container">
                    <div className="navbar_content">
                        <div id="News-logo" className="News-logo" onClick={activateHomeLink}>
                            <Link to="/">
                                <img id="NewsLogo" src={websettings && websettings.web_header_logo} alt="logo" />
                            </Link>
                        </div>

                        <div className="Manu-links">
                            <ul className="">
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/" onClick={setActiveLink}>
                                            {translate("home")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/about-us" onClick={setActiveLink}>
                                            {translate("aboutus")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/live-news" onClick={setActiveLink}>
                                            {translate("livenews")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/breaking-news-view" onClick={setActiveLink}>
                                            {translate("breakingnews")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/contact-us" onClick={setActiveLink}>
                                            {translate("contactus")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="Nav-btns" className="d-flex">
                                    {isLogin() && checkUserData(userData) ? (
                                        <Dropdown>
                                            <Dropdown.Toggle id="btnSignIn" className="me-2">
                                                <BiUserCircle size={23} id="btnLogo" />
                                                {userName}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                                <Dropdown.Item id="btnLogout">
                                                    <Link id="btnBookmark" to="/bookmark">
                                                        {translate("bookmark")}
                                                    </Link>
                                                </Dropdown.Item>
                                                {/*<Dropdown.Item id='btnLogout' onClick={changePassword}>*/}
                                                {/*    Change Password*/}
                                                {/*</Dropdown.Item>*/}
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={logout} id="btnLogout" className="">
                                                {translate("logout")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Button variant="danger" onClick={() => setModalShow(true)} id="btnSignIn" className="" type="button">
                                            <BiUserCircle size={23} id="btnLogo" />
                                            {translate("loginLbl")}
                                        </Button>
                                    )}

                                    {isLogin() ? (
                                        <Link to="/notification" id="btnNotification" type="button" className="btn">
                                            <BiBell size={23} /><span className="noti_badge_data">{notificationbadge}</span>
                                        </Link>
                                    ) : null}

                                    {/* <button id='btnSerch' type="button" onClick={handleSearchModel} className="btn"><BiSearch size={23} /></button> */}
                                    {/* {['bottom-end'].map((placement) => (
                                        <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                            <Popover.Body id='nb-popover-body' >
                                            <form id='serchModal' className="d-flex" role="search">
                                                                <input id='inputSerchForm' className="form-control me-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>{setSearch(e.target.value)}}/>
                                                                <Link id='btnSerchForm' className="btn" type="submit" to={`/search-news-view?Search=${Search}`}>Search</Link>
                                                            </form>
                                            </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <Button id='btnSerch' className="btn" variant="secondary"><BiSearch size={23} /></Button>
                                        </OverlayTrigger>
                                    ))} */}
                                </li>
                            </ul>

                            <SignInModal setIsLogout={setIsLogout} setisloginloading={setisloginloading} show={modalShow} setLoginModalShow={setModalShow} onHide={() => setModalShow(false)} />
                        </div>
                        <div className="hamburger-manu">
                            {["end"].map((placement, idx) => (
                                <OffCanvasExample key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                    </div>
                </div>
            </nav>
            <div className={ShowManu ? "mobile-manu2" : "mobile-manu"}></div>
        </>
    );
};

export default Newsbar;
