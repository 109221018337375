import React from "react";
import { FiCalendar } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import { getnewsApi } from "../store/actions/campaign";

function SearchNewsView() {
    const [Data, setData] = useState([]);
    const query = useQuery();
    const Search = query.get("Search");

    useEffect(() => {
        getnewsApi(
            "0",
            "",
            "",
            { Search },
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [Search]);

    return (
        <div>
            <div id="ts-main">
                <div id="ts-content" className="">
                    <div className="row">

                        {Data &&
                            Data.map((element) => (
                                <div className="col-md-4 " key={element.id}>
                                    <Link id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                                        <div id="ts-card" className="card">
                                            <img id="ts-card-image" src={element.image} className="card-img" alt="..." />

                                            <div id="ts-card-body" className="card-body">
                                                <button id="ts-btnCatagory" className="btn btn-sm" type="button">
                                                    {element.category_name}
                                                </button>
                                                <h5 id="ts-card-title" className="card-title">
                                                    {element.title.slice(0, 150)}...
                                                </h5>
                                                <p id="ts-card-date">
                                                    <FiCalendar size={18} id="ts-logoCalendar" />
                                                    {element.date.slice(0, 10)}
                                                </p>
                                                {/* <Link id='btncvRead' className='btn overlay' type="button" to="/news-view" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchNewsView;
