import React from 'react'
import { Route, Routes } from 'react-router'
import HomePage from '../Componenets/HomePage'
import TagNewsview from '../Componenets/TagNewsview'
import BreakingNews from '../Componenets/BreakingNews'
import VideoNewsview from '../Componenets/VideoNewsview'
import SearchNewsView from '../Componenets/SearchNewsView'
import RecentNewsView from '../Componenets/RecentNewsView'
import BookmarkSection from '../Componenets/BookmarkSection'
import NewsView from '../Componenets/NewsView'
import CategoryView from '../Componenets/CategoryView'
import Categories from '../Componenets/Categories'
import AboutUs from '../Componenets/AboutUs'
import LiveNews from '../Componenets/LiveNews'
import BreakingNewsView from '../Componenets/BreakingNewsView'
import Notification from '../Componenets/Notification'
import ContactUs from '../Componenets/ContactUs'
import NewsNotification from '../Componenets/NewsNotification'

const Router = () => {
  return (
    <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/tag-news-view" element={<TagNewsview />}></Route>
        <Route exact path="/breaking-news" element={<BreakingNews />}></Route>
        <Route exact path="/video-news-view" element={<VideoNewsview />}></Route>
        <Route exact path="/search-news-view" element={<SearchNewsView />}></Route>
        <Route exact path="/recent-news-view" element={<RecentNewsView />}></Route>
        <Route exact path="/bookmark" element={<BookmarkSection />}></Route>
        <Route exact path="/news-view" element={<NewsView />}></Route>
        <Route exact path="/category-view" element={<CategoryView />}></Route>
        <Route exact path="/categories" element={<Categories />}></Route>
        <Route exact path="/about-us" element={<AboutUs />}></Route>
        <Route exact path="/live-news" element={<LiveNews />}></Route>
        <Route exact path="/breaking-news-view" element={<BreakingNewsView />}></Route>
        <Route exact path="/notification" element={<Notification />}></Route>
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route exact path="/news-notification" element={<NewsNotification />}></Route>
        <Route exact path="/persnol-notification" element={<Notification />}></Route>
    </Routes>

  )
}

export default Router