import React, { useState, useEffect } from "react";
import BreadcrumbNav from "./BreadcrumbNav";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { getpagesApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";

function ContactUs() {
    const [loading, setLoading] = useState(true);

    const [Data, setData] = useState({
        page_content: "",
    });

    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        setLoading(true);
        getpagesApi(
            (response) => {
                let contactUs = response.data.filter((data) => data.title === "Contact Us");
                setData(contactUs[0]);
                setLoading(false);
            },
            (error) => {
                setData("");
                setLoading(false);
                console.log(error);
            }
        );
    }, [currentLanguage]);

    return (
        <>
            <BreadcrumbNav SecondElement="Contact Us" ThirdElement="0" />

            <div id="main_contact_us">
                <div className="main-aboutus">
                    <div id="about-us" className="container">
                        <div id="about">
                            <h2 className="A my-4" style={{ color: "#1B2D51" }}>
                                <strong>{translate("contactus")}</strong>
                            </h2>
                            {loading ? <Skeleton count={5} /> : Data.page_content ? <p dangerouslySetInnerHTML={{ __html: Data.page_content }} /> : <p>{translate("nodatafound")}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
